import type { ForwardRefExoticComponent, RefAttributes } from 'react'

import type { IconProps } from '@phosphor-icons/react'
import {
  ApplePodcastsLogo,
  ChalkboardTeacher,
  CheckCircle,
  MinusCircle,
  WarningCircle,
  Graph,
  House,
  Code,
  Student,
  User,
  UserCircleGear,
  Gear,
  UserCircle,
  Presentation,
  List,
  Plus,
  AddressBook,
  ArrowsClockwise,
  MagnifyingGlass,
  CalendarCheck,
  Bell,
  ArrowSquareOut,
  Calendar,
  Detective,
  Pause,
  XCircle,
  Quotes,
  BellRinging,
  ArrowCounterClockwise,
  SelectionBackground,
  Gauge,
  HourglassLow,
  ClockCounterClockwise,
  Archive,
  VideoCamera,
  X,
  ArrowElbowDownRight,
  Copy,
  UsersThree,
  FlagPennant,
  TreeStructure,
  Table,
  Pulse,
  Star,
  NewspaperClipping,
  IdentificationBadge,
  Chat,
} from '@phosphor-icons/react'

export type { IconProps } from '@phosphor-icons/react'

export type Icon = ForwardRefExoticComponent<
  IconProps & RefAttributes<SVGSVGElement>
>

export const createButtonIcon = <Plus size={16} weight="bold" />
export const copyButtonIcon = <Copy size={16} weight="bold" />

export const AccountSettingsIcon = UserCircleGear
export const ActivityLogIcon = Pulse
export const AttendeeOptionalIcon = Detective
export const CalendarIcon = Calendar
export const CancelMentorSessionIcon = X
export const CheckCircleIcon = CheckCircle
export const CoursesIcon = Graph
export const DashboardIcon = House
export const DevtoolIcon = Code
export const DropIcon = XCircle
export const ExternalLinkIcon = ArrowSquareOut
export const GraduateIcon = CheckCircle
export const JoinEventMeetingIcon = VideoCamera
export const LearnerEnrollmentIcon = ApplePodcastsLogo
export const LearnerIcon = Student
export const LearningVelocityIcon = Gauge
export const MakeupMentorSessionIcon = ArrowElbowDownRight
export const MentorIcon = ChalkboardTeacher
export const MentorScheduleIcon = CalendarCheck
export const MentorSessionBookingRequestIcon = HourglassLow
export const MentorSessionIcon = Presentation
export const MentorSessionRecapIcon = Quotes
export const MentorSessionRatingIcon = Star
export const MentorTimeSheetIcon = Table
export const MinusCircleIcon = MinusCircle
export const NotificationsIcon = Bell
export const NotificationsUnseenIcon = BellRinging
export const OrgChart = TreeStructure
export const PostponeIcon = Pause
export const ProfileTabIcon = UserCircle
export const SalesDealIcon = AddressBook
export const SearchIcon = MagnifyingGlass
export const SettingsTabIcon = Gear
export const SidebarTogglerIcon = List
export const StatusesIcon = SelectionBackground
export const SyncIcon = ArrowsClockwise
export const UndoIcon = ArrowCounterClockwise
export const UserIcon = User
export const WarningCircleIcon = WarningCircle
export const EnrollmentDateIcon = UsersThree
export const MentorSessionBookingRequestStatusIcon = Calendar
export const MentorSessionBookingRequestCancelConfirmDialogIcon = Archive
export const Performance = FlagPennant
export const MentorSessionEventStartDateIcon = Calendar
export const RescheduleActivityIcon = ClockCounterClockwise
export const JobBoardIcon = NewspaperClipping
export const StaffIcon = IdentificationBadge
export const LLMPlaygroundIcon = Chat
