import { UserRole } from '../graphql'

export const LOCAL_STORAGE_TOKEN = 'Authorization'
export const LOCAL_STORAGE_USER_EMAIL = 'User-Email'
export const LOCAL_STORAGE_USER_ID = 'User-Id'
export const LOCAL_STORAGE_V2_APP_STORE = 'app-state'
export const MS_BOOKING_OPTS = 'MS-Booking-Opts'
export const MS_BOOKING_REQUEST_OPTS = 'MS-Booking-Request-Opts'
export const DEAL_LIST_HIDE_ENROLLED = 'Deal-List-Hide-Enrolled'
export const LEARNER_ACTIVE_MENTOR_SESSION_ID =
  'Learner-Active-Mentor-Session-Id'

export const WORKPLACE_ROLES = [
  UserRole.ACADEMIC_MANAGER,
  UserRole.ACADEMIC_STAFF,
  UserRole.ADMIN,
  UserRole.DEVELOPER,
  UserRole.SALES_MANAGER,
  UserRole.SALES_STAFF,
  UserRole.GROWTH_MANAGER,
  UserRole.GROWTH_STAFF,
  UserRole.HR,
  UserRole.LEARNER_EXP,
  UserRole.OPS_MANAGER,
  UserRole.OPS_STAFF,
  UserRole.TEACHING_ASSISTANT,
]

export const LINKS = {
  SIGN_IN: '/v2/auth/sign-in',
  CREATE_ACCOUNT: '/v2/auth/create-account',
  CONNECT_DISCORD: '/v2/auth/connect-discord',
  RESET_PASSWORD: '/auth/reset-password/email',
  COURSE_LIST: '/workplace/academics/courses',
  COURSE_DURATION_OPTION:
    '/v2/workplace/academics/courses/:courseId/duration-options',
  CREATE_COURSE: '/workplace/academics/courses/create',
  ENROLL_TO_LW: '/workplace/academics/enrollments/pending',
  VIEW_COURSE: '/workplace/academics/courses/:courseId',
  LW_COURSE_AUTHOR:
    'https://learn.coderschool.vn/author/course?courseid=:courseId',
  USER_VIEW: '/workplace/users/:userId',
  DEAL_VIEW: '/workplace/academics/enrollments/deals/:dealId',
  DEAL_ENROLL: '/workplace/academics/enrollments/deals/:dealId/enroll',
  LEARNER_COURSE_MEMBER_LIST: '/v2/workplace/learner-success/learners',
  LEARNER_LIST: '/workplace/academics/learners',
  LEARNER_PROFILE: '/workplace/academics/learners/:learnerId/profile',
  LEARNER_COURSE_LIST:
    '/workplace/academics/learners/:learnerId/profile/courses',
  MENTOR_LIST: '/workplace/academics/mentors',
  MENTOR_VIEW: '/workplace/academics/mentors/:mentorId',
  MENTOR_PROFILE: '/workplace/academics/mentors/:mentorId/profile',
  PD_DEAL_VIEW:
    'https://coderschool.pipedrive.com/deal/:dealId?utm_source=csplatform&utm_medium=academic&utm_campaign=deal_view_click',
  CALENDAR_VIEW: '/workplace/calendar',
  CALENDAR_UPDATE_EVENT: '/workplace/calendar/events/:eventId/update',
  MENTOR_SESSION_LIST: '/workplace/mentor-sessions/sessions',
  MENTOR_SESSION_EVENT_LIST: '/v2/workplace/mentor-sessions/events',
  MENTOR_SESSION_BOOKING_REQUEST_LIST:
    '/workplace/mentor-sessions/booking-requests',
  MENTOR_TIMESHEET_VIEW: '/workplace/mentor-sessions/timesheet',
  CREATE_MENTOR_SESSION: '/workplace/mentor-sessions/sessions/booking',
  CREATE_MENTOR_SESSION_BOOKING_REQUEST:
    '/workplace/mentor-sessions/booking-requests/create',
  MENTOR_SESSION_VIEW: '/workplace/mentor-sessions/sessions/:mentorSessionId',
  MENTOR_SESSION_SETTINGS:
    '/workplace/mentor-sessions/sessions/:mentorSessionId/settings',
  MENTOR_SESSION_BOOKING_REQUEST_VIEW:
    '/workplace/mentor-sessions/booking-requests/:requestId',
  LEARNER_INCIDENTS: '/v2/workplace/learner-success/incidents',
  INSTANT_MEETING: '/v2/workplace/learner-success/instant-meetings',
  DISCORD_CHANNELS: '/v2/workplace/learner-success/discord-channels',
  MENTOR_SESSION_MEETING_VIEW: '/v2/meeting/mentor-session/:mentorSessionId',
  DASKALOS_MEETING_VIEW:
    '/v2/daskalos/mentor-sessions/:mentorSessionId/preview',
  JOB_BOARD_LIST: '/v2/workplace/career-support/job-board',
  JOIN_INSTANT_MEETING: '/v2/meeting/:meetingId/join',
  ZOOM_MEETING: 'https://us06web.zoom.us/j/:zoomMeetingId',
  LLM_PLAYGROUND: '/v2/workplace/llm',
} as const

export const MENTOR_LINKS = {
  MENTOR_DASHBOARD: '/mentor',
  MENTOR_SESSION_LIST: '/mentor/mentor-sessions',
  MENTOR_SESSION_VIEW: '/mentor/mentor-sessions/:mentorSessionId',
  MENTOR_SETTINGS: '/mentor/settings',
  LEARNER_LIST: '/mentor/learners',
  MSBR_LIST: '/mentor/booking-requests',
  MENTOR_SESSION_BOOKING_REQUEST_VIEW: '/mentor/booking-requests/:requestId',
} as const

export const LEARNER_LINKS = {
  LEARNER_DASHBOARD: '/learner',
  MENTOR_SESSION_VIEW: '/learner/mentor-sessions/:mentorSessionId',
  LEARNER_SETTINGS: '/learner/settings',
  LEARNER_SETTINGS_AVAILABILITY: '/learner/settings/availability',
} as const

export const COURSE_CONTENTS_EDITOR = {
  MAX_UNIT_LEVEL: 2,
  EDITABLE_ROLES: [UserRole.ADMIN],
} as const

export const MENTOR_SESSION = {
  DURATION_OPTIONS: [30, 45, 60, 75, 90, 120, 150, 180],
  DEFAULT_DURATION: 90,
} as const

export const LW_COURSE_ID = {
  WEB: 'ftw-virgil',
  DS: 'da-virgil',
} as const

export const ADD_TO_GOOGLE_CALENDAR_BASE_URL = `https://calendar.google.com/calendar/u/0/r/eventedit`
export const ZOOM_RECORDING_API_PATH = '/api/zoom/recording/'
